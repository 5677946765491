<template>
 <p>Edit for the event here</p>
</template>

<script>
export default{
    props:['event']
}
</script>

